/* Navbar.css */

.header {
  position: fixed;
  width: 120%;
  top: 10px;
  left: 0px; /* Cambiar de 0 a -10% */
  background-color: transparent;
  z-index: var(--z-fixed);
}

.nav {
  background-color: #161616; /* Color de fondo del navbar */
  padding: 30px; /* Espaciado interno */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
}

.nav__logo {
  color: #f7f7f7;
  transition: color 0.4s;
  font-size: 3em; /* Ajusta el tamaño del logo según sea necesario */
  font-family: var(--second-font);
  cursor: pointer;
}

.nav__toggle {
  font-size: 2rem;
  color: var(--title-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 10px; /* Ajusta el espacio entre los iconos */
  position: relative;
}

.nav__toggle:hover::after{
  content: "Menú"; /* Texto del tooltip para el icono de menú */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Color de fondo del tooltip */
  color: #fff; /* Color del texto del tooltip */
  padding: 5px 10px; /* Espaciado interno del tooltip */
  border-radius: 5px; /* Bordes redondeados del tooltip */
  font-size: 12px; /* Tamaño de fuente del tooltip */
}

.login-icon {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.login-icon:hover::after {
  content: "Iniciar de Sesión"; /* Texto del tooltip para el icono de inicio de sesión */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Color de fondo del tooltip */
  color: #fff; /* Color del texto del tooltip */
  padding: 5px 10px; /* Espaciado interno del tooltip */
  border-radius: 5px; /* Bordes redondeados del tooltip */
  font-size: 12px; /* Tamaño de fuente del tooltip */
}


.nav__icons {
  display: flex;
  align-items: center;
}

.nav__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem; /* Posiciona la "x" en la esquina superior derecha */
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0; /* Posicionar el menú a la derecha */
  background-color: #5d5b5b;
  width: 20%; /* Establecer el ancho al 30% */
  padding: 5rem;
}

.nav__menu.active {
  display: block;
}

.nav__list {
  list-style: none;
  padding: 0;
}

.nav__item {
  margin-top: 1px;
  font-size: 20px;
  margin-bottom: 3rem;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  text-decoration: none;
}

.nav__link:hover {
  color: var(--first-color);
}

.cerrar-sesion {
  list-style: none;
  margin-top: 1px;
  font-size: 10px;  
}

.btn-cerrarSesion {
  background-color: transparent;
  color: inherit;
  border: none;
  cursor: pointer;
  padding: 0;
}

.btn-cerrarSesion span {
  font-weight: var(--font-semi-bold);
  font-size: 1.7em; /* Ajusta el tamaño según tu preferencia */
}

.btn-cerrarSesion:hover {
  color: red;
}

@media only screen and (max-width: 768px) {
  .header {
    padding-right: 20px;
  }

  .nav__menu {
    display: none;
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
    background-color: #5d5b5b;
    padding: 2rem;
    width: 100%;
  }

  .nav__list {
    margin-top: 2rem;
  }

  .nav__item {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .nav__link {
    font-size: 1rem;
  }

  .nav__toggle {
    font-size: 1.2rem;
  }

  .nav__menu.active {
    display: block;
  }

  .nav__logo {
    font-size: 1.5em;
    margin-right: -20px;
  }

  .cerrar-sesion {
    font-size: 6px;
    margin-left: -20px;  
  }

  .nav__icons {
    display: flex;
    align-items: center;
    margin-right: -20px;
  }

  .login-icon {
    font-size: 1.2rem;
  }
}
