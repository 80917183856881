/* Estilos para el contenedor principal */
.App {
  display: flex; /* Utiliza flexbox para alinear los elementos */
  flex-direction: column; /* Alinea los elementos en una columna */
  align-items: center; /* Centra los elementos horizontalmente */
  position: relative; /* Asegura que los elementos hijos se posicionen relativamente a este contenedor */
}

.navbar-container {
  width: 100%; /* Ajusta el ancho del contenedor del navbar */
  margin-top: -15px; /* Espacio superior entre el navbar y el contenido siguiente */
  overflow: hidden; /* Oculta el desbordamiento si es necesario */
  position: fixed; /* Fija el navbar en la parte superior de la ventana */
  top: 0; /* Coloca el navbar en la parte superior de la ventana */
  z-index: 2; /* Asegura que el navbar esté por encima del mapa */
  padding-right: 20px;
}

.contenedor-padre {
  display: flex; /* Utiliza flexbox para dividir la pantalla en dos contenedores */
  width: 95%; /* Toma todo el ancho disponible */
  height: auto; /* la altura de la ventana */
  margin-top: 20px;
}

.contenedor-padre2 {
  display: flex; /* Utiliza flexbox para dividir la pantalla en dos contenedores */
  width: 95%; /* Toma todo el ancho disponible */
  height: auto; /* la altura de la ventana */
  margin-top: 5px; /* Espacio superior entre el navbar y los contenedores */
}


.contenedor-hijo, .mapa-container, .contenedor-hijo2 {
  width: 50%; /* Cada contenedor ocupa la mitad del ancho del contenedor padre */
  height: 100%; /* Toma toda la altura disponible */
  overflow: hidden; /* Oculta el desbordamiento si es necesario */
  margin-top: 5px;
}

.mapa-container {
  margin-bottom: '10px';
  margin-top: -10px;
  overflow: hidden; /* Oculta el desbordamiento si es necesario */
  z-index: 1; /* Asegura que el mapa esté detrás del navbar */

}

.slider-container {
  width: 95%; /* Ajusta el ancho del contenedor del slider */
  overflow: hidden; /* Oculta el desbordamiento si es necesario */
  margin-top: 120px;
  margin-bottom: 20px;
}

.footer-container {
  width: 100%; /* Ajusta el ancho del contenedor del footer */
  margin-top: 25px; /* Espacio superior entre el footer y los demás elementos */
  overflow: hidden; /* Oculta el desbordamiento si es necesario */
}

/* Estilos para el contenedor de Miniban */
.miniban-container {
  height: 100%; /* Ocupa toda la altura disponible */
  overflow-y: auto; /* Agrega desplazamiento vertical si es necesario */
}
.botones-container {
  width: 100%;
  overflow: hidden;
  padding-right: 20px;/* Desplaza el contenido hacia la derecha */
}

@media (max-width: 768px) {
  
  .contenedor-padre {
    flex-direction: column;
    width: 97%;
    margin-top: 1px;
  }

  .contenedor-padre2 {
    flex-direction: column;
    width: 97%;
    margin-top: 30px;
  }
  .contenedor-hijo,
  .mapa-container,.contenedor-hijo2 {
    width: 100%;
    margin-top: -10px;
  }

  .slider-container {
    width: 95%;
    margin-top: 105px;
  }

  .navbar-container {
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding-right: 20px; 
  }

}
