.search-input {
    width: 80%; /* Ocupa todo el ancho del contenedor padre */
    padding: 5px; /* Ajusta el relleno interno */
    font-size: 18px; /* Ajusta el tamaño del texto */
    box-sizing: border-box; /* Incluye el relleno y el borde en el ancho total */
    border: 5px solid #ccc; /* Borde ligero */
    border-radius: 20px; /* Bordes redondeados */
    outline: none; /* Elimina el contorno por defecto al enfocar */
    transition: border-color 0.3s; /* Animación suave al cambiar el color del borde */ /* Bordes redondeados */
    margin-top: 10px;
}

/*-------------------------*/
.comment-box {
  margin: 20px;
}

.comment-box textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.comment-box button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.comment-box button:hover {
  background-color: #45a049;
}

.comments-list {
  margin-top: 20px;
}

.comment-item {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
    .search-input {
      padding: 8px;
      font-size: 20px;
      border: 2px solid #ccc;
      border-radius: 8px;
      margin-top: 1px;
    }
  }
  
  @media (max-width: 480px) {
    .search-input {
      padding: 6px;
      font-size: 16px;
      border: 2px solid #ccc;
      border-radius: 6px;
      margin-top: 1px;
    }
  }