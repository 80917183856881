.empleado-container {
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    transition: border-color 0.3s ease-in-out;
}

.empleado-container:hover {
    border-color: #007BFF;
}

.empleado-title {
    text-shadow: 0 0 2px #0c0c0c;
    font-weight: bold;
    margin-bottom: 5px;
    color: #0c0c0c;
}

.empleado-info {
    display: grid;
    grid-template-columns: 1.2fr 2fr;
    gap: 10px;
    margin-bottom: 15px;
}

.empleado-info .empleado-info2> p {
    margin: 0;
    font-size: 1.1rem;
}

.empleado-info > p:nth-child(odd):nth-child(1),
.empleado-info > p:nth-child(odd):nth-child(3),
.empleado-info > p:nth-child(odd):nth-child(5),
.empleado-info > p:nth-child(odd):nth-child(7),
.empleado-info > p:nth-child(odd):nth-child(9),
.empleado-info > p:nth-child(odd):nth-child(11),
.empleado-info > p:nth-child(odd):nth-child(13),
.empleado-info > p:nth-child(odd):nth-child(15),
.empleado-info > p:nth-child(odd):nth-child(17) {
    padding-right: 10px;
    font-size: 1.1rem;
    color: #0a0808;
    max-height: 2em;
    text-align: justify;
}

.empleado-info > p:nth-child(even):nth-child(2),
.empleado-info > p:nth-child(even):nth-child(4),
.empleado-info > p:nth-child(even):nth-child(6),
.empleado-info > p:nth-child(even):nth-child(8),
.empleado-info > p:nth-child(odd):nth-child(10) {
    text-align: left;
    padding-left: 10px;
    font-size: 1rem;
    color: #555;
}

.empleado-info > p:nth-child(odd) {
    text-align: right;
    padding-right: 10px;
}

.empleado-info > p:nth-child(even) {
    text-align: left;
    padding-left: 10px;
}

.empleado-info2 {
    padding-right: 20px;
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
    text-align: left;
    padding-right: 135px;
}

.descripcion-texto {
    white-space: pre-wrap;
    margin: 0;
}

.linea-con-guion {
    display: block;
    padding-left: 1em;
    text-indent: -1em;
    font-size: 15px;
}

.precios {
    font-weight: bold;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.empleado-dialog-title {
    width: 100%;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #ddd;
    text-shadow: 0 0 2px #0c0c0c;
}

.empleado-dialog-title span {
    font-weight: normal;
    color: #555;
}

.close-button-container {
    position: absolute;
    margin-right: 15px;
    top: 8px;
    right: 8px;
}

.imgGeneral-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
}

.padre-conteiner {
    display: flex;
    flex-direction: row;
}

.imagen-container {
    flex: 0 0 40%;
}

.empleado-info {
    margin-bottom: 5px;
    flex: 0 0 60%;
}

.datos {
    display: flex;
    gap: 10px;
}

.datos-item {
    list-style: none;
    margin: 0;
    padding: 0;
}

.datos-item a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.datos-item a i {
    margin-right: 5px;
}

.empleado-dialog-title {
    background-color: #333;
    color: #fff;
}

.dialog-content-custom {
    background-color: #e9e7e7;
}

.fachada-container {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.fachada-container img {
    max-width: 100%;
    height: 350px;
}

.datos-resposive {
    display: none;
}

.datos-normal {
    display: flex;
    gap: 10px;
}

.empleado-info3 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
}

.empleado-info3 i {
    margin-right: 5px;
    font-size: 16px;
    color: #007BFF;
}

.font-modal {
    font-weight: 700;
}

@media (max-width: 768px) {
    .datos-normal {
        display: none;
    }
    .datos-resposive {
        display: flex;
        gap: 10px;
    }
    .padre-conteiner {
        flex-direction: row;
        gap: 10px;
    }

    .imagen-container {
        flex: 0 0 50%;
    }

    .empleado-info {
        flex: 0 0 50%;
    }

    .empleado-info > p:nth-child(odd):nth-child(1),
    .empleado-info > p:nth-child(odd):nth-child(3),
    .empleado-info > p:nth-child(odd):nth-child(5),
    .empleado-info > p:nth-child(odd):nth-child(7) {
        text-align: left;
        padding: 0;
        font-size: 12px;
    }

    .empleado-info > p:nth-child(even):nth-child(2),
    .empleado-info > p:nth-child(even):nth-child(4),
    .empleado-info > p:nth-child(even):nth-child(6),
    .empleado-info > p:nth-child(even):nth-child(8) {
        text-align: left;
        padding: 0;
        font-size: 13px;
    }

    .imgGeneral-image {
        width: 140px;
        height: 150px;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .empleado-title {
        font-size: 14px;
    }

    .datos-item {
        margin-bottom: 10px;
    }

    .datos {
        display: flex;
        gap: 10px;
    }

    .datos-item {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .datos-item a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .datos-item a i {
        margin-right: 5px;
    }
    .linea-con-guion {
        font-size: 12px;
    }
    .empleado-info3 {
        font-size: 12px;
    }
    .empleado-info3 p {
        margin: 1px 0;
    }
    .fachada-container img {
        max-width: 100%;
        height: 250px;
    }
}
