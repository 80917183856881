
/* Estilos para los botones */
.botones {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Por ejemplo, cuatro columnas */
    justify-content: center; /* Centrar horizontalmente */
    max-width: 1200px; /* Ancho máximo deseado */
    margin-bottom: 20px;
}

.botones .btn {
    background-color: #0760c0;
    margin: 20px 40px; /* Ajusta el espacio horizontal entre los botones */
}

.btn {
    text-decoration: none;
    font-size: 0.7rem;
    position: relative;
    margin: 16px 0; /* Ajusta el margen vertical */
    display: flex;
    align-items: center; /* Alinea verticalmente */
    justify-content: center; /* Centra horizontalmente */
    letter-spacing: 1px;
    min-height: auto;
    width: 90%; /* Ajustado para ocupar el ancho del contenedor */
    box-sizing: border-box;
}

.btn i {
    margin-right: 10px; /* Espacio entre el icono y el texto */
}

/* Estilos específicos para el primer botón */
.btn-1 {
    color: white;
    background-color: #dfa043; /* Color de fondo del botón */
    border-radius: 40px;    
    box-shadow: 0 0 0 0 rgba(143, 64, 248, 0.5), 0 0 0 0 rgba(39, 200, 255, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    padding: 10px 20px; /* Agrega un relleno de 10px arriba y abajo, 20px a la izquierda y derecha */
    margin-bottom: 40px; /* Agrega espacio en la parte inferior */
}

.btn-1::after {
    content: "";
    height: 400px;
    position: absolute;
    top: -50px;
    left: -100px;
    background: #13ff13;
    background-image: linear-gradient(-225deg, #06011b 0%, #03168f 29%, #08daff 67%, #FFF800 100%);
    z-index: -1;
    transition: transform 0.5s ease;
}

.btn-1:hover {
    transform: translate(0, -6px);
    box-shadow: 10px -10px 25px 0 rgba(16, 226, 253, 0.5), -10px 10px 25px 0 rgba(183, 0, 255, 0.794);
}

.btn-1:hover::after {
    transform: rotate(150deg);
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
/*--------------------------------------*/
.opciones {
    padding: 20px;
}


.tarjetas {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.tarjeta {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    padding: 20px;
    background-color: #614343;
    border-radius: 10px;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s;
}

.tarjeta:hover {
    transform: scale(1.05);
}

.tarjeta i {
    font-size: 2rem;
    margin-bottom: 10px;
}

.tarjeta h3 {
    margin: 10px 0;
}

.tarjeta p {
    text-align: center;
}


/*footer css*/
footer {
    background-color: #0c0c2475;
    padding: 10px 40px; /* Ajusta el padding según sea necesario */
    display: flex;
    width: 100%; /* Ancho completo */
    position: relative; /* Cambia la posición a relativa */
    bottom: 0; /* Lo posiciona en la parte inferior */
    left: 0; /* Lo alinea al lado izquierdo */
    right: 0; /* Lo alinea al lado derecho */
}


.link {
	margin-top: 10px;
    margin-right: 300px; /* Añade espacio entre las columnas de enlaces */
    flex-direction: column; /* Alinear elementos en columna */
    align-items: flex-start; /* Alinear elementos a la izquierda */
}
.btn i{
    font-size: 18px;
}
.link ul li {
    display: flex; /* Cambiar a flexbox */
    align-items: center; /* Alinear elementos verticalmente */
}

.link ul li i {
    margin-right: 5px; /* Espacio entre el icono y el texto */
}

.link h4{
    color: #f7f3f3;
    text-transform: uppercase;
    margin-bottom: 15px;
	font-size: 20px;
}

.link ul li a{
    color: #d6d4d4;
    font-size: 14px;
    margin-bottom: 1px; /* Ajusta el espacio vertical entre los enlaces */
    line-height: 2;
}


.grupo-2{
	background-color: #0a0247;
	padding:  0px;
	text-align: center;
	color: #f7f4f4;
}

.grupo-2 small{
	font-size: 13px;
}

@media only screen and (max-width: 700px) {
    .link {
        
        flex-direction: column;
        margin-right:130px;
        margin-top: 5px; /* Reduce el espacio superior */
    }

    .link h4 {
        font-size: 14px; /* Ajusta el tamaño de la fuente */
        margin-bottom: 5px; /* Ajusta el espacio inferior */
    }

    .link ul {
        display: flex;
        flex-direction: column; /* Cambia la dirección a columna */
        align-items: flex-start; /* Alinea los elementos a la izquierda */
    }

    .link ul li a {
        font-size: 10px; /* Ajusta el tamaño de la fuente */
        margin-bottom: 3px; /* Ajusta el espacio vertical entre los enlaces */
    }

    .link i{
        font-size: 15px;
    }


.grupo-2 {
    background-color: #0a0247;
    padding: 10px; /* Añade un espacio interior */
    text-align: center;
    color: #f7f4f4;
}

.grupo-2 small {
    font-size: 12px; /* Reduce el tamaño de la fuente */
}

.btn-1 {
    padding: 10px 5px; /* Reducción del relleno en dispositivos pequeños */
    font-size: 0.6rem; /* Reducción del tamaño de la fuente en dispositivos pequeños */
    box-sizing: border-box; /* Incluye el padding y el border en el ancho total */
}
.botones {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Dos columnas en dispositivos móviles */
    justify-content: flex-start; /* Alinear los botones hacia la izquierda en dispositivos móviles */
    width: 100%;
    margin-bottom: 20px;
}

.botones .btn {
    margin: 15px 20px; /* Ajusta el espacio horizontal y vertical entre los botones */
}

}

@media (max-width: 768px) {
    .tarjetas {
        gap: 8px;
    }

    .tarjeta {
        width: 45%;
        padding: 15px;
    }

    .tarjeta i {
        font-size: 1rem;
    }

    .tarjeta h3 {
        margin: 8px 0;
        font-size: 1.2rem;
        text-align: center;
    }

    .tarjeta p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .tarjetas {
        justify-content: space-between;
    }

    .tarjeta {
        width: 45%;
        padding: 10px;
    }

    .tarjeta i {
        font-size: 1.2rem;
    }

    .tarjeta h3 {
        margin: 6px 0;
        font-size: 0.8rem;
    }

    .tarjeta p {
        font-size: 0.6rem;
    }

}