.map-container {
  text-align: center;
  width: 100%;
  min-height: 600px;
  margin-bottom: 50px;
}

.search-input {
  margin-bottom: 20px;
  padding: 11px 15px;
  width: 80%;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leaflet-container {
  height: 600px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5%;
}

.map-popup .nom-emp {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.map-popup .direccion {
  color: #3f3e3e;
  font-size: 1em;
}

.add-marker-button {
  margin-bottom: 10px;
  padding: 5px 20px;
  width: 30%;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

}