
.column-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.miniban-image {
    width: 220px; /* Ajustar el ancho de la imagen según sea necesario */
    height: 250px; /* Mantener la proporción de la imagen */
    margin-right: 20px; /* Espacio entre la imagen y la información */
}

.miniban-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1;
    overflow-y: auto; /* Permitir desplazamiento vertical */
    white-space: pre-line; /* Mantener los saltos de línea */
}

/* Otros estilos para la información */
h1 {
    font-family: 'Times New Roman', serif;
    font-size: 32px;
    font-weight: bold;
    color: #aca7a7;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 25px;
}


.miniban-container {
    display: flex;
    align-items: center; /* Alinear elementos verticalmente */
    border: 1px solid #686767; /* Borde del contenedor */
    background-color: #131212; /* Nuevo color de fondo */
    border-radius: 8px; /* Bordes redondeados */
    padding: 20px; /* Espaciado interno */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Sombra */
    margin-bottom: 20px; /* Espaciado inferior */
}



/* Estilos para la información de la promoción */
.miniban-info h2 {
    font-size: 1.6em; /* Tamaño de la fuente del título */
    text-shadow: 0 0 2px #f3f3f3;
    font-weight: bold;
    margin-bottom: 5px;
    color: #e6e3e3;
}

/* Estilos para los párrafos de la información de la promoción */
.miniban-info p {
    margin: 0px 0; /* Espaciado vertical */
    color: #5f5d5d;
}

/* Estilos para el botón "Ver más" */
.ver-mas-btn {
    background-color: #0760c0; /* Color de fondo */
    color: rgb(230, 229, 229); /* Color del texto */
    border: none; /* Sin borde */
    padding: 10px 15px; /* Espaciado interno */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cursor apuntador */
    transition: background-color 0.3s; /* Transición suave */
}

/* Estilos para el botón "Ver más" al pasar el mouse por encima */
.ver-mas-btn:hover {
    background-color: #0056b3; /* Color de fondo más oscuro */
}

.iconos-horizontal2 .link-item {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 15px; /* Espacio entre los íconos */
}

.empleado-info4 {
    text-align: left;
}

.empleado-info4 p {
    font-size: 1.1rem;
    display: block;
    color:  #a4aac0;
    line-height: 1.5;
}

.empleado-info4 i {
    font-size: 1rem;
    margin-right: 5px; /* agregar un margen derecho para separar el icono del texto */
}
.titulo-2 {
    text-align: center;
    font-family: Comic Sans MS; 
    font-weight:bold; 
    font-size: 50px; 
    color: #EBD758; 
    text-shadow: -1px 0 #414D68, 0 1px #414D68, 1px 0 #414D68, 0 -1px #414D68, -2px 2px 0 #414D68, 2px 2px 0 #414D68, 1px 1px #414D68, 2px 2px #414D68, 3px 3px #414D68, 4px 4px #414D68, 5px 5px #414D68; 
    margin-bottom: 20px;
}

.font-bold {
    font-weight: 800;
}

@media (max-width: 768px) {
    .miniban-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .miniban-image {
        width: 100%;
        height: 200px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .miniban-info {
        align-items: stretch;
    }

    .miniban-info h2 {
        font-size: 18px; /* Ajustar el tamaño del título para pantallas pequeñas */
    }

    .miniban-info p, .link-item a  {
        font-size: 11px; /* Ajustar el tamaño del texto para pantallas pequeñas */
    }
    .miniban-info p {
        font-size: 11px;
        margin-top: -2
        px;
    }

    
    .ver-mas-btn {
        padding: 8px 15px; /* Ajustar el padding para pantallas pequeñas */
        font-size: 12px; /* Ajustar el tamaño de fuente para pantallas pequeñas */
    }

    .link-item {
        margin-right: 10px; /* Ajustar espacio entre íconos para pantallas pequeñas */
        margin-bottom: 0px; /* Espacio inferior para separación entre elementos en columna */
    }


    .link-item a i {
        margin-right: 5px; /* Reducir espacio entre icono y texto */
    }

    h1{
        font-size: 20px;
    }

    .contenedor-padre2, .contenedor-padre{
        padding: 7px;
        
    }

    .contenedor-hijo, .contenedor-hijo2{
        padding: 7px;
    }

    .miniban-info {
        padding: 10px;
    }

    .contenedor-hijo,.mapa-container {
        width: 100%;
        flex: 1;
        margin-top: -60px;
    }

    .contenedor-padre2 {
        flex-direction: column;
    }

    .mapa-container{
        margin: 0 auto;
        padding: 0px 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: -13px;
    }
    .titulo-2 {
        font-size: 30px; 
    }
}

