/* Estilos generales */
#app {
    height: 100%;
}
html, body {
    position: relative;
    height: 100%;
}
body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #f7f3f3;
    margin: 0;
    padding: 0;
}
.promoSlider {
    width: 100%; /* Ajusta este valor según tus necesidades */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Agrega una sombra suave */
    border-radius: 15px; /* Añade bordes redondeados */
    overflow: hidden; /* Asegura que los contenidos no se desborden */
}
.promoSlider .swiper {
    width: 100%;
    height: 150px; /* Reducir la altura del slider para pantallas pequeñas */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
}
.promoSlider .swiper-slide {
    background-color: #f8f8f8;
    padding: 10px; /* Reducir el padding para pantallas pequeñas */
    text-align: center;
    font-size: 14px; /* Reducir el tamaño de la fuente para pantallas pequeñas */
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-container-wrapper {
    display: flex;
    flex-direction: column; /* Alinea los elementos en columna */
    align-items: center;
}
.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #189adb;
}
.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #189adb;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}
.slide-container {
    display: flex;
    align-items: center;
    max-width: 1000px;
    border-radius: 15px; /* Añade bordes redondeados */
    overflow: hidden; /* Asegura que los contenidos no se desborden */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Agrega una sombra suave */
    background-color: #fff;
}
.taxi-image {
    width: 250px;
    height: 140px; /* Ajusta la altura según tus necesidades */
    object-fit: cover; /* Mantiene la relación de aspecto de la imagen */
    border-radius: 5px; /* Añade bordes redondeados */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Agrega una sombra suave */
}
.taxi-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px; /* Reducir el espacio entre elementos */
    flex: 1;
    overflow-y: auto;
    white-space: pre-line;
    padding: 15px; /* Reducir el padding para pantallas pequeñas */
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.titulo-1 {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100%; /* Opcional: ajusta la altura si es necesario */
}
.titulo-1 h2 {
    font-size: 1.2em; /* Tamaño de la fuente del título */
    text-shadow: 0 0 2px #050505;
    font-weight: bold;
    margin-bottom: 1px;
    color: #050505;
    text-align: center; /* Centra horizontalmente */
    margin-left: 100px; /* Ajusta el espacio a la izquierda del texto */
}
.contenido {
    text-align: left; /* Alinea el texto a la izquierda */
    padding-left: 0px; /* Ajusta el padding izquierdo del contenido */
    gap: 5px;
}
.contenido p {
    font-size: 12px;
    margin-top: 5px;
    color: #666;
    gap: 5px; 
}
.iconos-horizontal{
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en filas */
    gap: 10px; /* Espacio entre elementos */
    align-items: center;
    margin-top: -5px;
    margin-bottom: 5px;
}
.iconos-horizontal .link-item , .contenido .link-item {
    display: flex; /* Hace que los elementos dentro de .link-item se muestren en línea */
    align-items: center; /* Alinea verticalmente los elementos */
    gap: 5px; 
    font-size: 12px;
}
.iconos-horizontal .link-item a, .contenido .link-item a {
    text-decoration: none; /* Quita la decoración de subrayado de los enlaces */
    color: inherit; /* Usa el color del texto padre */
    display: flex; /* Hace que los elementos se muestren en línea */
    align-items: center; /* Alinea verticalmente los elementos */
    gap: 3px;
    font-size: 12px;
}
.iconos-horizontal .link-item i {
    font-size: 12px; /* Tamaño de los íconos */
}
.link-item {
    margin-top: 5px; /* Tamaño de los íconos */
}
.icon-link {
    display: flex;
    align-items: center;
    gap: 5px; /* Espacio entre el icono y el texto */
    position: relative; /* Necesario para el posicionamiento absoluto del texto en pantallas pequeñas */
}





@media screen and (max-width: 768px) {

    .promoSlider {
        width: 100%; /* Asegura que el slider ocupe todo el ancho disponible en pantallas pequeñas */
    }

    .slide-container {
        flex-direction: row; /* Cambiar a fila en pantallas pequeñas */
        justify-content: center; /* Centrar elementos */
      }
    
      .taxi-image {
        width: 80px; /* Mantener el mismo ancho de imagen */
        height: 80px; /* Mantener el mismo alto de imagen */
        margin-right: 1px; /* Espacio a la derecha de la imagen */
      }

    .taxi-info {
        flex: 1;
        padding: 10px;
    }

    .titulo-1 h2 {
        font-size: 1rem; /* Ajusta el tamaño del título para pantallas pequeñas */
        margin-bottom: 1px; /* Reduce el margen inferior */
        margin-left: 20px;
    }

    .taxi-info p {
        font-size: 10px; /* Ajusta el tamaño del texto para pantallas pequeñas */
        margin-bottom: 1px; /* Reduce el margen inferior */
    }

    .autoplay-progress {
        right: 8px; /* Ajusta la posición desde el borde derecho */
        bottom: 8px; /* Ajusta la posición desde el borde inferior */
        width: 40px; /* Ajusta el tamaño del contenedor de progreso */
        height: 40px; /* Ajusta el tamaño del contenedor de progreso */
    }

    .autoplay-progress svg {
        stroke-width: 3px; /* Ajusta el ancho del trazo */
    }

    .iconos-horizontal .link-item {
        display: flex; /* Hace que los elementos dentro de .link-item se muestren en línea */
        align-items: center; /* Alinea verticalmente los elementos */
        gap: 2px; 
        font-size: 12px;
    }
    .iconos-horizontal .link-item a, .contenido .link-item  a {
        font-size: 10px;
        gap: 1px; 
    }
    .iconos-horizontal .link-item i, .contenido .link-item i {
        font-size: 10px; /* Tamaño de los íconos */
        gap: 1px; 
    }
    .link-item {
        margin-top: 1px; /* Tamaño de los íconos */
    }

    .iconos-horizontal {
        margin-top: 2px;
         gap: 2px; /* Espacio entre elementos */
    }
    .icon-text {
        display: none; /* Oculta el texto en pantallas pequeñas */
    }

    .icon-link:hover .icon-text {
        display: inline-block; /* Muestra el texto al pasar el cursor sobre el enlace */
        background-color: transparent; /* Sin fondo en pantallas pequeñas */
        color: inherit; /* Mantiene el color del texto del enlace */
        padding: 0; /* Sin padding */
        position: static; /* Posicionamiento normal */
        transform: none; /* Sin transformación */
    }

}

@media screen and (min-width: 769px) {
    .icon-link:hover .icon-text {
        display: inline-block; /* Asegura que el texto esté visible al pasar el cursor en pantallas grandes */
    }
}