.container {
  width: 80%;
  margin: 0 auto;
}

/* Estilos para la tabla */
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

/* Estilos para el encabezado de la tabla */
thead {
  background-color: #0e094a;
}

.tr {
  color: #131313;
}

th {
  padding: 8px;
  text-align: left;
}

/* Estilos para las celdas de la tabla */
td {
  padding: 8px;
  text-align: left;
}

/* Estilos para las filas impares de la tabla */
tbody {
  background-color: #131313;
}

/* Estilos para las filas al pasar el mouse */
tbody tr:hover {
  background-color: #615f5f;
}

.logout-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #eb0b0b;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/* Estilos para el botón de registro */
.registro-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.registro-button:hover {
  background-color: #45a049;
}

/* Estilos para los botones de editar y eliminar */
.eliminar-button {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #eb0b0b;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.editar-button {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.editar-button:hover {
  background-color: #1bcdec;
}

.eliminar-button:hover {
  background-color: #be0019;
}

/* Estilos para el selector de tabla */
select {
  margin-right: 20px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: white; /* Ajusta el color de fondo según tu diseño */
  color: #333; /* Color del texto */
  width: 30%; /* Ajusta el ancho según tu diseño */
}

select option {
  padding: 8px;
  background-color: rgb(121, 113, 113); /* Ajusta el color de fondo según tu diseño */
  color: #f7f0f0; /* Color del texto */
}

/* Registros */
.registrar-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.registrar-container label {
  display: block;
  margin-bottom: 5px;
}

.registrar-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.registrar-container button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.registrar-container button:hover {
  background-color: #45a049;
}

.registrar-container button[type="button"] {
  background-color: #f44336;
}

.registrar-container button[type="button"]:hover {
  background-color: #d32f2f;
}

.edit-container-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Habilitar desplazamiento vertical */
}

/* Editar */
.edit-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #9b9494;
  max-height: 80vh; /* Limitar la altura máxima del contenedor */
  overflow-y: auto; /* Habilitar desplazamiento vertical */
}

.edit-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.edit-container button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.edit-container button:hover {
  background-color: #45a049;
}

.edit-container button[type="button"] {
  background-color: #f44336;
}

.edit-container button[type="button"]:hover {
  background-color: #d32f2f;
}

/*---------------------------*/
.trabajando-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f0f0; /* Cambia esto al color de fondo que prefieras */
}

.trabajando-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.trabajando-text {
  font-size: 2rem;
  color: #333; /* Cambia esto al color de texto que prefieras */
}

/* Overlay for edit container */

