@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

:root {
    --header-height: 3.5rem;
    --first-color: hsl(28, 88%, 62%);
    --title-color: hsl(0, 0%, 95%);
    --text-color: hsl(0, 0%, 75%);
    --body-color: hsl(0, 0%, 6%);
    --body-font: "Montserrat", sans-serif;
    --second-font: "Dancing Script", cursive;
    --h2-font-size: 1.25rem;
    --small-font-size: 0.813rem;
    --font-semi-bold: 600;
    --z-tooltip: 10;
    --z-fixed: 100;
}

body {
    background-color: var(--body-color);
    color: var(--text-color);
    font-family: var(--body-font);
}

ul {
    list-style: none;
}

body {
    background-image: url(./imagenes/fondo1.jpg);
    background-color: #333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.fondo-encuentranos {
    /* Estilos para la imagen de fondo en la página de inicio de sesión */
    background-color: antiquewhite;
}

main {
    width: 100%;
    padding: 20px;
    margin: auto;
    margin-top: 100px;
}

.contenedor__todo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh; /* Ajusta la altura al 100% del viewport */
}

.contenedor__login-register {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 380px;
    position: relative;
    transition: top 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
 /* boton de inicio con google*/
.formulario__login,
.formulario__register {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formulario__login input,
.formulario__register input,
.formulario__login button,
.formulario__register button {
    margin: 8px 0;
}

.toggle-section {
    margin-top: 16px;
}
/*  fin boton de inicio con google*/
.contenedor__login-register form {
    width: 100%;
    padding: 20px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.contenedor__login-register h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    color: #46A2FD;
}

.contenedor__login-register input {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    background: #F2F2F2;
    font-size: 16px;
    outline: none;
}

.contenedor__login-register button {
    padding: 10px 40px;
    margin-top: 20px;
    border: none;
    font-size: 14px;
    background: #46A2FD;
    font-weight: 600;
    cursor: pointer;
    color: white;
    outline: none;
    transition: background-color 300ms;
}

.contenedor__login-register button:hover {
    background-color: #0056b3;
}

.toggle-section {
    margin-top: 20px;
    text-align: center;
    color: #f2f2f2;
}

/* Estilos para la superposición (overlay) */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Estilos para la ventana del modal */
.modal {
    background-color: rgb(73, 72, 72);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

.modal p {
    margin: 0 0 20px 0;
    font-size: 1.2em;
}

.modal button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: rgb(255, 255, 255);
    font-size: 1em;
    cursor: pointer;
}

.modal button:hover {
    background-color: #0056b3;
}
@media screen and (max-width: 850px) {
    main {
        margin-top: 50px;
    }

    .contenedor__todo {
        max-width: 350px;
    }

    .contenedor__login-register {
        top: 0;
        left: 0;
        margin: auto;
    }

    .contenedor__login-register form {
        position: relative;
    }
}



